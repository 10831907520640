import React from "react";
import "../../css/rohit.css";
import Pride from "../../Components/OurPride";
const F5 = () => {
  return (
    <div>
      <div className="top-banner">
        <div className="conatiner">
          <h1 className="text-center top-banner-title">Disha Haryani</h1>
          <p className="text-center top-banner-subtitle">Faculty</p>
        </div>
      </div>

      <div className="container">
        <div className="row mt-5 mb-5">

          <div className="col-md-4">
            <img
              className="w-100"
              src={require("../../img/Disha_mam.png")}
              alt="loading..."
            />
            <h2 className="faculty-title ps-3">Disha Haryani</h2>
            <h3 className="faculty-sub-title ps-3">
            An experienced and dedicated educator who is passionate about fostering learning in her students.
            </h3>
          </div>
          <div className="col-md-8">
            <p className="para">
            Meet Disha Haryani - an experienced and dedicated educator who is passionate about fostering learning in her students. <br />
            With over 12 years of teaching experience, she has positively impacted the lives of more than 7000 students<br />
              She is seasoned doctor with demonstrated record of accomplishment in skilled
              inspiring and guiding minds of various age groups on her educational journey.
              Disha's amiable personality and strong people orientation have enabled her to . <br />
              efficiently work in various areas, including personality development, corporate training, IELTS, OET, and TOEFL training. If you're looking for a committed and reliable educator who can help you reach your full potential, Disha is the one for you.
             
            </p>
          </div>

        </div>
      </div>
      <Pride />
    </div>
  );
};

export default F5;
