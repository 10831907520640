import React, { useState, useEffect } from 'react';
import '../css/rohit.css'
import '../css/chandan.css'
import NumberCounter from './NumberCounter'

const OurPride = () => {



  const [isVisible, setIsVisible] = useState(false);

  const handleScroll2 = () => {
    const targetElement = document.querySelector('.heading-title');
    if (targetElement) {
      const elementTop = targetElement.getBoundingClientRect().top;
      const isVisible = elementTop < window.innerHeight;
      setIsVisible(isVisible);
    }
  };

  useEffect(() => {
    const handleScroll = () => {
      const targetElement = document.getElementById('middleDiv');
      if (!targetElement) return;

      const elementRect = targetElement.getBoundingClientRect();
      const windowHeight = window.innerHeight;

      if (elementRect.top <= windowHeight / 2 && elementRect.bottom >= windowHeight / 2) {
        handleScroll2();
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (

    <>
      <div id="middleDiv" >

        <div className="container" id="yourTargetDiv">
          <div className="row ">
            <div className="col-md-12 mt-5">
              <p className="text-center text-red heading-title my-md-4 my-sm-3 ">Our Pride </p>
              <div className="row  d-flex justify-content-center my-4"  >

                <div className="col-md-4 col-lg-3 text-center">
                  {/* <Link to='/iit-testimonial'> */}
                  <div className="row our-pride-card">
                    <div className="col-5">
                      <img src={require('../img/pride-1img.png')} alt="" className='w-100 my-4' />
                    </div>
                    <div className="col-7 text-left my-3">
                      <h3 className="reg-counts" id='number-counter'> {isVisible && <NumberCounter endValue={2500} duration={2000} />}+</h3>
                      <h4 className="reg-profession">IITians</h4>
                    </div>
                  </div>
                  {/* </Link> */}
                </div>

                <div className="col-md-4 col-lg-3 text-center">
                  {/* <Link to='/doctor-testimonial'> */}
                  <div className="row our-pride-card">
                    <div className="col-5">
                      <img src={require('../img/pride-2img.png')} alt="" className='w-100 my-4' />
                    </div>
                    <div className="col-7 text-left my-3">
                      <h3 className="reg-counts">{isVisible && <NumberCounter endValue={1500} duration={2000} />}+</h3>
                      <h4 className="reg-profession">Doctors</h4>
                    </div>
                  </div>
                  {/* </Link> */}
                </div>

                <div className="col-md-4 col-lg-3 text-center">
                  {/* <Link to='/eng-testimonial'> */}

                  <div className="row our-pride-card">
                    <div className="col-5">
                      <img src={require('../img/pride-3img.png')} alt="" className='w-100 my-4' />
                    </div>
                    <div className="col-7 text-left my-3">
                      <h3 className="reg-counts">{isVisible && <NumberCounter endValue={60000} duration={2000} />}+</h3>
                      <h4 className="reg-profession">Engineers</h4>
                    </div>
                  </div>
                  {/* </Link> */}
                </div>

              </div>
            </div>
          </div>
        </div>

      </div>
    </>

  )
}

export default OurPride