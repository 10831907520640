import React, { Suspense, lazy } from 'react';
import ReactDOM from 'react-dom/client';
import './App.css'
import { Route, Routes, BrowserRouter } from 'react-router-dom';
import reportWebVitals from './reportWebVitals';
import PreLoader from './Components/PreLoader';

import F5 from '../src/Pagesnew/Facultys/F5.jsx'



const App = lazy(() => import('./App'));
const Login = lazy(() => import('./Pages/Login'));
const Registration = lazy(() => import('./Pages/Registration'));
const Class = lazy(() => import('./Pages/Class'));
const Exam = lazy(() => import('./Pages/Exam'));
const Subject = lazy(() => import('./Pages/Subject'));
const Subjectvedio = lazy(() => import('./Pages/Subjectvedio'));
const Subjectinner = lazy(() => import('./Pages/Subjectinner'));
const Forget = lazy(() => import('./Pages/Forget'));
const Examdetail = lazy(() => import('./Pages/Examdetail'));
const Subjectdetail = lazy(() => import('./Pages/Subjectdetail'));
const Classdetail = lazy(() => import('./Pages/Classdetail'));
const Singlevedio = lazy(() => import('./Pages/Singlevedio'));
const Liveactive = lazy(() => import('./Pages/Liveactive'));
const Liveprevious = lazy(() => import('./Pages/Liveprevious'));
const Liveupcoming = lazy(() => import('./Pages/Liveupcoming'));
const Livevedio = lazy(() => import('./Pages/Livevedio'));
const Chaptervedio = lazy(() => import('./Pages/Chaptervedio'));
const Payment = lazy(() => import('./Pages/Payment'));
const PayNow = lazy(() => import('./Pages/PayNow'));
const Watchhistory = lazy(() => import('./Pages/Watchhistory'));
const Watchhistoryvideo = lazy(() => import('./Pages/Watchhistoryvideo'));
const Singlehistoryvideo = lazy(() => import('./Pages/Singlehistoryvideo'));
const User = lazy(() => import('./Pages/User'));
const Otp = lazy(() => import('./Pages/Otp'));
const Enquiryotp = lazy(() => import('./Pages/Enquiryotp'));
const Carrear = lazy(() => import('./Pages/Carrear'));
const Thankyou = lazy(() => import('./Pages/Thankyou'));
const Enquirythankyou = lazy(() => import('./Pages/Enquirythankyou'));
const Mysubscription = lazy(() => import('./Pages/Mysubscription'));
const Cancelpayment = lazy(() => import('./Pages/Cancelpayment'));
const Securityerror = lazy(() => import('./Pages/Securityerror'));


// new route
const Home = lazy(() => import('../src/Pagesnew/Home'));
const Blog = lazy(() => import('../src/Pagesnew/Blog'));
const Blogdetail = lazy(() => import('../src/Pagesnew/Blogdetail'));
const AboutUs = lazy(() => import('../src/Pagesnew/AboutUs'));
const Career = lazy(() => import('../src/Pagesnew/Career'));
const ContactUs = lazy(() => import('../src/Pagesnew/ContactUs'));
const Courses = lazy(() => import('../src/Pagesnew/Courses'));
const Faculty = lazy(() => import('../src/Pagesnew/Faculty'));
const Internship = lazy(() => import('../src/Pagesnew/Internship'));
const InternshipApply = lazy(() => import('../src/Pagesnew/InternshipApply'));
const Training = lazy(() => import('../src/Pagesnew/Training'));
const F1 = lazy(() => import('../src/Pagesnew/Facultys/F1'));
const F2 = lazy(() => import('./Pagesnew/Facultys/F2'));
const F3 = lazy(() => import('./Pagesnew/Facultys/F3'));
const F4 = lazy(() => import('./Pagesnew/Facultys/F4'));

const PrivacyPolicy = lazy(() => import('../src/Pagesnew/PrivacyPolicy'));
const Term = lazy(() => import('../src/Pagesnew/Terms'));
const Tutors = lazy(() => import('../src/Pagesnew/Tutors'));

const Iit = lazy(() => import('../src/Pagesnew/CoursesDetail/Iitjee'));
const Enggmaths = lazy(() => import('../src/Pagesnew/CoursesDetail/Enggmaths.jsx'));
const CaCpt = lazy(() => import('../src/Pagesnew/CoursesDetail/CaCpt.jsx'));
const English = lazy(() => import('../src/Pagesnew/CoursesDetail/English.jsx'));
const TweelthClass = lazy(() => import('../src/Pagesnew/CoursesDetail/TweelthClass.jsx'));
const EleventhClass = lazy(() => import('../src/Pagesnew/CoursesDetail/EleventhClass.jsx'));
const TenthClass = lazy(() => import('../src/Pagesnew/CoursesDetail/TenthClass.jsx'));
const NinthClass = lazy(() => import('../src/Pagesnew/CoursesDetail/NinthClass.jsx'));
const EigthClass = lazy(() => import('../src/Pagesnew/CoursesDetail/EigthClass.jsx'));
const ComputerApp = lazy(() => import('../src/Pagesnew/CoursesDetail/ComputerApp.jsx'));
const ForignLang = lazy(() => import('../src/Pagesnew/CoursesDetail/ForignLang.jsx'));
const GeneralStudies = lazy(() => import('../src/Pagesnew/CoursesDetail/GeneralStudies.jsx'));
const EnggDrawing = lazy(() => import('../src/Pagesnew/CoursesDetail/EnggDrawing.jsx'));
const Neet = lazy(() => import('../src/Pagesnew/CoursesDetail/Neet.jsx'));
const Digitalmarketing = lazy(() => import('../src/Pagesnew/CoursesDetail/Digitalmarketing.jsx'));
const Clat = lazy(() => import('../src/Pagesnew/CoursesDetail/Clat.jsx'));
const CampustoCorporate = lazy(() => import('../src/Pagesnew/CoursesDetail/CampustoCorporate.jsx'));
const General_aptitude = lazy(() => import('../src/Pagesnew/CoursesDetail/General_aptitude.jsx'));
const Webdesign = lazy(() => import('../src/Pagesnew/CoursesDetail/Webdesign.jsx'));
const Cuet = lazy(() => import('../src/Pagesnew/CoursesDetail/Cuet.jsx'));
const Eleventhpcm = lazy(() => import('../src/Pagesnew/CoursesDetail/Eleventhpcm.jsx'));
const Eleventhcommerce = lazy(() => import('../src/Pagesnew/CoursesDetail/Eleventhcommerce.jsx'));
const Eleventhpcb = lazy(() => import('../src/Pagesnew/CoursesDetail/Eleventhpcb.jsx'));
const Tweelthcommerce = lazy(() => import('../src/Pagesnew/CoursesDetail/Tweelthcommerce.jsx'));
const Tweelthpcm = lazy(() => import('../src/Pagesnew/CoursesDetail/Tweelthpcm.jsx'));
const Tweelthpcb = lazy(() => import('../src/Pagesnew/CoursesDetail/Tweelthpcb.jsx'));
const Iittestimonial = lazy(() => import('../src/Pagesnew/Testimonials/Iittestimonial.jsx'));
const Engtestimonial = lazy(() => import('../src/Pagesnew/Testimonials/Engtestimonial.jsx'));
const Doctertestimonial = lazy(() => import('../src/Pagesnew/Testimonials/Doctertestimonial.jsx'));

const Wedesign = lazy(() => import('../src/internship/Wedesign.jsx'));
const Broadcastingintern = lazy(() => import('./internship/Broadcastingintern.jsx'));
const Digitalintern = lazy(() => import('./internship/Digitalintern.jsx'));
const Graphicintern = lazy(() => import('./internship/Graphicintern.jsx'));
const Leadintern = lazy(() => import('./internship/Leadintern.jsx'));
const Socialintern = lazy(() => import('./internship/Socialintern.jsx'));
const Stockintern = lazy(() => import('./internship/Stockintern.jsx'));
const Studiointern = lazy(() => import('./internship/Studiointern.jsx'));
const Videointern = lazy(() => import('./internship/Videointern.jsx'));
const Videoproduction = lazy(() => import('./internship/Videoproduction.jsx'));
const Marketingintern = lazy(() => import('./internship/Marketingintern.jsx'));


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(

  <BrowserRouter >
    <Suspense fallback={<PreLoader />}>
      <Routes>
        <Route path="/" element={<App />}>
          <Route path="/login" element={<Login />} />
          <Route path="/forget-password" element={<Forget />} />
          <Route path="/register" element={<Registration />} />
          <Route path="/class" element={<Class />} />
          <Route path="/exam" element={<Exam />} />
          <Route path="/classes/:id" element={<Examdetail />} />
          <Route path="/subject" element={<Subject />} />
          <Route path="/chapters/:id" element={<Subjectvedio />} />
          <Route path="/units/:id" element={<Subjectinner />} />
          <Route path="/subject-units/:id" element={<Subjectdetail />} />
          <Route path="/class-subjects/:id" element={<Classdetail />} />
          <Route path="/single-video" element={<Singlevedio />} />
          <Route path="/live-active/:id" element={<Liveactive />} />
          <Route path="/live-previous/:id" element={<Liveprevious />} />
          <Route path="/live-upcoming/:id" element={<Liveupcoming />} />
          <Route path="/live-video" element={<Livevedio />} />
          <Route path="/chapter-video/:id" element={<Chaptervedio />} />
          <Route path="/payment" element={<Payment />} />
          <Route path="/pay-now" element={<PayNow />} />
          <Route path="/watch-history" element={<Watchhistory />} />
          <Route path="/watch-history-video" element={<Watchhistoryvideo />} />
          <Route path="/single-history-video" element={<Singlehistoryvideo />} />
          <Route path="/user-profile" element={<User />} />
          <Route path="/otp" element={<Otp />} />
          <Route path="/enquiry-otp" element={<Enquiryotp />} />
          <Route path="/career-otp" element={<Carrear />} />
          <Route path="/thank-you" element={<Thankyou />} />
          <Route path="/enquiry-thankyou" element={<Enquirythankyou />} />
          <Route path="/my-subscription" element={<Mysubscription />} />
          <Route path="/payment-cancel" element={<Cancelpayment />} />
          <Route path="/security-error" element={<Securityerror />} />


          {/* new Route */}
          <Route path="/" element={<Home />} />
          <Route path="/about-us" element={<AboutUs />} />
          <Route path="/courses" element={<Courses />} />
          <Route path="/careers" element={<Career />} />
          <Route path="/faculty" element={<Faculty />} />
          <Route path="/blog" element={<Blog />} />
          <Route path="/:blog" element={<Blogdetail />} />
          <Route path="/internships-and-industrial-trainings" element={<Training />} />
          <Route path="/one-to-one-home-tutions" element={<Tutors />} />

          <Route path="/internship-apply" element={<InternshipApply />} />
          <Route path="/dr-dipti-rami" element={<F1 />} />
          <Route path="/mrs-anshu-khadode" element={<F2 />} />
          <Route path="/mr-shankey-bansal" element={<F3 />} />
          <Route path="/shrikant-nisthala" element={<F4 />} />
          <Route path="/disha_haryani" element={<F5 />} />
          <Route path="/contact-us" element={<ContactUs />} />
          <Route path="/terms-conditions" element={<Term />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />

          <Route path="/iit-jee" element={<Iit />} />
          <Route path="/neet-and-aiims" element={<Neet />} />
          <Route path="/digital-marketing" element={<Digitalmarketing />} />
          <Route path="/web-design" element={<Webdesign />} />
          <Route path="/campus-to-corporate" element={<CampustoCorporate />} />
          <Route path="/clat" element={<Clat />} />
          <Route path="/ca-cpt" element={<CaCpt />} />
          <Route path="/english" element={<English />} />
          <Route path="/12th-class" element={<TweelthClass />} />
          <Route path="/11th-class" element={<EleventhClass />} />
          <Route path="/10th-class" element={<TenthClass />} />
          <Route path="/9th-class" element={<NinthClass />} />
          <Route path="/8th-class" element={<EigthClass />} />
          <Route path="/computer-application" element={<ComputerApp />} />
          <Route path="/foreign-language" element={<ForignLang />} />
          <Route path="/general-studies" element={<GeneralStudies />} />
          <Route path="/general-aptitude" element={<General_aptitude />} />
          <Route path="/engg-maths" element={<Enggmaths />} />
          <Route path="/engg-drawing" element={<EnggDrawing />} />
          <Route path="/cuet" element={<Cuet />} />

          <Route path="/12th-class-pcm" element={<Tweelthpcm />} />
          <Route path="/12th-class-pcb" element={<Tweelthpcb />} />
          <Route path="/12th-class-commerce" element={<Tweelthcommerce />} />
          <Route path="/11th-class-pcm" element={<Eleventhpcm />} />
          <Route path="/11th-class-pcb" element={<Eleventhpcb />} />
          <Route path="/11th-class-commerce" element={<Eleventhcommerce />} />
          <Route path="/iit-testimonial" element={<Iittestimonial />} />
          <Route path="/eng-testimonial" element={<Engtestimonial />} />
          <Route path="/doctor-testimonial" element={<Doctertestimonial />} />


          <Route path="/website-design" element={<Wedesign />} />
          <Route path="/live-broadcasting" element={<Broadcastingintern />} />
          <Route path="/digital-marketings" element={<Digitalintern />} />
          <Route path="/graphic-designing" element={<Graphicintern />} />
          <Route path="/lead-management-and-sales" element={<Leadintern />} />
          <Route path="/marketing-and-promotion" element={<Marketingintern />} />
          <Route path="/social-media-management" element={<Socialintern />} />
          <Route path="/stock-market-analysis" element={<Stockintern />} />
          <Route path="/videography-and-studio-handling" element={<Studiointern />} />
          <Route path="/video-editing" element={<Videointern />} />
          <Route path="/video-production" element={<Videoproduction />} />


        </Route>
      </Routes>
    </Suspense>
  </BrowserRouter>

);

reportWebVitals();
