import React, { useState, useEffect } from 'react';

const NumberCounter = ({ endValue, duration }) => {
  const [count, setCount] = useState(0);

  useEffect(() => {
    const handleScroll = () => {
      const targetElement = document.getElementById('number-counter'); // Adjust the ID accordingly
      if (!targetElement) return;

      const elementTop = targetElement.getBoundingClientRect().top;
      const isVisible = elementTop < window.innerHeight;

      if (isVisible) {
        let startTime;
        const startCount = count;
        const step = (timestamp) => {
          if (!startTime) startTime = timestamp;
          const progress = Math.min(1, (timestamp - startTime) / duration);
          setCount(Math.floor(startCount + progress * (endValue - startCount)));
          if (progress < 1) {
            requestAnimationFrame(step);
          }
        };
        requestAnimationFrame(step);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [endValue, duration, count]);

  return (
    <div id="number-counter">{count}</div>
  );
};

export default NumberCounter;
